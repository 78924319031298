import React, { useEffect, useMemo, useState } from 'react';
import CreateIconComponent from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';
import DOMPurify from 'dompurify';

export type SvgIconFromSrcUrlProps = {
  className?: string;
  src: string;
};

const SvgIconFromSrcUrl = ({ className, src }: SvgIconFromSrcUrlProps): JSX.Element => {
  const [svgSrcText, setSvgSrcText] = useState<string>();
  const [svgFill, setSvgFill] = useState<string>('none');

  // Fetch the svg from the url and save the file text
  useEffect(() => {
    const fetchUrl = () => {
      fetch(src, {
        method: 'GET',
      })
        .then(res => res.text())
        .then(svg => {
          setSvgSrcText(svg);
        })
        .catch(() => undefined);
    };
    if (src) {
      fetchUrl();
    }
  }, [src]);

  const extractedSvgText = useMemo(() => {
    // Get any special fill rules
    if (svgSrcText) {
      const svgRegex = /<svg[^>]*>/;
      const fillRegex = /fill=".+?"/;

      const sanitized = DOMPurify.sanitize(svgSrcText);
      const firstSvgTag = sanitized.match(svgRegex);
      const svgTagFill = firstSvgTag?.[0].match(fillRegex);
      const hexMatch = svgTagFill?.[0].match(/[#]\w{6}/)?.[0];
      if (hexMatch) {
        setSvgFill('currentColor');
      }

      // Insert inner_svg into svgSrcText
      const innerSvg = sanitized.match(/<svg[^>]*>([\s\S]*?)<\/svg>/)?.[1];

      // Replace stroke hex
      if (!innerSvg) return null;
      const replacedStroke = innerSvg.replace(/stroke="[#]\w{6}"/g, 'stroke="currentColor"');

      // Replace fill hex
      return replacedStroke.replace(/fill="[#]\w{6}"/g, 'fill="currentColor"');
    }
    return undefined;
  }, [svgSrcText]);

  return (
    <CreateIconComponent height={24} width={24} fill={svgFill} className={className}>
      {/* eslint-disable-next-line react/no-danger */}
      {extractedSvgText && <g dangerouslySetInnerHTML={{ __html: extractedSvgText }} />}
    </CreateIconComponent>
  );
};

export default SvgIconFromSrcUrl;
