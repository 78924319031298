import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

const max1920 = css`
  max-width: 1920px;
  width: 100%;
  ${MEDIA_QUERY_V2.DESKTOP_MASSIVE} {
    border-left: 1px solid ${COLORS3.BLACK_030};
    border-right: 1px solid ${COLORS3.BLACK_030};
  }
`;

export const cssFix = css`
  height: fit-content !important;
  ${MEDIA_QUERY_V2.DESKTOP} {
    grid-template-rows: minmax(max-content, 100%);
  }

  // updates for shorter desktops to override css above
  // for masthead height updates https://newamsterdamlabs.atlassian.net/browse/CE-3020
  ${`@media (min-width: 1200px) and (max-height: 750px)`} {
    height: 100% !important;
    max-height: unset !important;
    min-height: unset;
  }
`;

export const GridTextItem = styled.div`
  height: 100%;
  width: 100%;
  padding: ${SPACING.S24};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS3.WHITE_100};
  ${MEDIA_QUERY_V2.MOBILE} {
    display: none;
  }
`;

export const Text = styled.span`
  text-align: center;
  ${FONT.textSizeCompact(FONT.FONT_SIZE_LARGER)};
  ${MEDIA_QUERY_V2.DESKTOP} {
    ${FONT.textSizeUltraCompact(FONT.FONT_SIZE_SEMI_HUGE)};
  }
`;

export const Highlight = styled.span`
  font-family: ${FONT.FONT_FAMILY_NEW_SPIRIT};
`;

export const CtaSection = styled.section`
  border-top: 1px solid ${COLORS3.BLACK_030};
  ${max1920};
  width: 100%;
  padding: 56px ${SPACING.S24};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    padding: 64px 56px;
    align-items: center;
  }
`;

export const CtaText = styled.span`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_LARGER)};
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    text-align: center;
  }
  ${MEDIA_QUERY_V2.TABLET} {
    ${FONT.textSizeCompact('42px')};
  }
  ${MEDIA_QUERY_V2.DESKTOP} {
    ${FONT.textSizeCompact(FONT.FONT_SIZE_SEMI_HUGE)};
  }
  ${MEDIA_QUERY_V2.DESKTOP_XL} {
    ${FONT.textSizeUltraCompact('64px')};
  }
`;

export const CtaButton = styled(ButtonV3)`
  margin-top: ${SPACING.S16};
  ${MEDIA_QUERY_V2.TABLET} {
    margin-top: ${SPACING.S24};
  }
  ${MEDIA_QUERY_V2.DESKTOP} {
    margin-top: ${SPACING.S32};
  }
`;
