import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import experimentFlags, { CLP_LP_LINKS_TO_SRP_V2 } from 'libs/client/utils/experimentFlags';

/**
 * Trigger marketplace-clp-lp-links-to-srp experiment viewed, since we only want it triggered when the link is clicked
 * @param userContext
 */
export const triggerSrpLinkExperimentViewed = (userContext: UserContext | null | undefined) => {
  if (typeof window !== 'undefined' && userContext && !userContext.is_guest && isDesktopV2()) {
    experimentFlags.isUnderTest(CLP_LP_LINKS_TO_SRP_V2, userContext);
  }
};

export const getIsInVendorSRPLinkExperiment = (userContext: UserContext | null | undefined) => {
  return (
    typeof window !== 'undefined' &&
    userContext &&
    !userContext.is_guest &&
    isDesktopV2() &&
    experimentFlags.getExperimentVariation(CLP_LP_LINKS_TO_SRP_V2, userContext, false) === 'TEST-2'
  );
};
