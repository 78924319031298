import Head from 'next/head';
import React from 'react';
import { EXPERT_ADVICE_META_IMAGE } from 'constants/expertAdvice';

type EAHeaderProps = {
  title: string;
};

const description =
  'A treasure trove of wedding wisdom! Find answers to all your wedding questions, from how to pick a venue, to wedding invitation wording, to building the perfect registry.';

export default function Header({ title }: EAHeaderProps): JSX.Element {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="copyright" content="© 2024 Zola, Inc. All rights reserved. " />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={EXPERT_ADVICE_META_IMAGE} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.zola.com/expert-advice" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="zola.com" />
      <meta property="og:phone_number" content="1-408-657-ZOLA" />
      <meta property="fb:app_id" content="215495315286955" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@zola" />
      <meta name="twitter:creator" content="@zola" />

      <link rel="canonical" href="https://www.zola.com/expert-advice" />
    </Head>
  );
}
